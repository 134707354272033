<template>
<div>
    <a-radio-group v-model="type" button-style="solid" style="margin-bottom: 20px" @change="onChangeType">
        <a-radio-button value="hot">
            热歌榜
        </a-radio-button>
        <a-radio-button value="soar">
            飙升榜
        </a-radio-button>
        <a-radio-button value="original">
            原创榜
        </a-radio-button>
    </a-radio-group>
    <div class="header_message">
      <span>更新周期：每24小时，每天早上10点前</span
      ><span class="updata_times">上次更新：{{ updataTime }}</span>
    </div>
    <v-table ref="table" :columns="columns" :remote="remote"  :query="query"  :pagination="pagination" :row-key="(record, index) => index" @sendTime='sendTime'>
        <template #author="{row}">
            <polymers-music :value="row" :dataType="$root.dataType"></polymers-music>
        </template>

        <template #operation="{row}">
            <a type="link" @click="openMusic(row.shareUrl)">播放音乐</a>
        </template>
    </v-table>
</div>
</template>

<script>
import PolymersMusic from "@/components/widgets/polymers-music"
let defaultQuery = {
    rank_type: 'hot'
}

export default {
    components: {
        PolymersMusic
    },
    data() {
        return {
            type: 'hot',
            loading: false,
            query: _.cloneDeep(defaultQuery),
            visible: false,
            preview_src: '',
            pagination: { pageSize: 10 },
            updataTime:'',
        }
    },
    computed: {
        columns() {
            return [
                {title: "基本信息", dataIndex: "author", scopedSlots: {customRender: 'author'}, ellipsis: true },
                {title: "时长", dataIndex: "duration", align: 'center', width: 150},
                {title: "使用次数", dataIndex: "useCount", align: 'center', customRender: this.$dict.humanReadable, width: 150},
                {title: "操作", align: 'center', scopedSlots: {customRender: "operation"}, width: 150}
            ]
        },
    },
    mounted() {
        this.$on("init", this.init);
    },
    methods: {
        onChangeType(e) {
            let type = e.target.value
            this.query.rank_type = type
            this.init()
        },
        openMusic(url) {
            window.open(url)
        },
        init(options = {}) {
            this.$nextTick(() => {
                this.$refs.table.load();
            })
        },
        remote(qp) {
            return this.$api.core.Polymers.music(qp);
        },
        sendTime(value){
            this.updataTime = value
        }
    }
}
</script>
<style lang="less" scoped>
.header_message {
  font-size: 15px;
  font-weight: 500;
  margin: 8px 0;
  text-align: right;
}
.updata_times {
  display: inline-block;
  margin: 0 20px;
}
</style>